/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
 import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 export const initialState = {
    womenMen : 0,
    distance : 0,
    number : 5,
    customer: false,
    provider: true,
    alternateProvider: true,
 }
 
 export type EstablishmentObjectiveState = typeof initialState;
 
 export const establishmentObjectiveSlice = createSlice({
     name: 'establishmentObjectives',
     initialState,
     reducers: {
        setCustomer: (state, action: PayloadAction<boolean>) => {
            state.customer = action.payload ;
        },
        setProvider: (state, action: PayloadAction<boolean>) => {
            state.provider = action.payload ;
        },
        setAlternateProvider: (state, action: PayloadAction<boolean>) => {
            state.alternateProvider = action.payload ;
        },        
        setWomenMen: (state, action: PayloadAction<number>) => {
            state.womenMen = action.payload ;
        },
        setDistance: (state, action: PayloadAction<number>) => {
            state.distance = action.payload ;
        },
        setNumber: (state, action: PayloadAction<number>) => {
            state.number = action.payload ;
        },
     }
 });
 
 export default establishmentObjectiveSlice.reducer;
 
 export const { setDistance, setNumber, setWomenMen, setCustomer, setProvider, setAlternateProvider } = establishmentObjectiveSlice.actions;