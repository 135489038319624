/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import React from "react";
import { Trans } from 'react-i18next';

export default class ErrorBoundary extends React.Component {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error:any) {
    return { error: error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error: { error, errorInfo } });
  }
  render() {
    if (this.state.error) {
      return (
        <div className="p-16 text-center text-2xl">
          <Trans>Une erreur est survenue.</Trans>
        </div>
      );
    }
    return this.props.children;
  }
}
