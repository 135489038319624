/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AvgBiomType, EtablissementShortType, PartnersType } from "../api/Etablissements";

export type EstablishmentsState = {
    establishments?: EtablissementShortType[]
    partners: PartnersType[]
    employer: EtablissementShortType[]
    biom: AvgBiomType
    parity?: number    
};

export const initialState:EstablishmentsState = {
    partners: [],
    employer: [],
    biom: {
        C1: null,
        C2: null,
        C3: null,
        C4: null,
        C5: null,
        C6: null,
        C8: null,
        nbr: 0,
        biom: null,
    },
 }
 
 export const establishmentsSlice = createSlice({
     name: 'establishments',
     initialState,
     reducers: {
        setEstablishments: (state, action: PayloadAction<EtablissementShortType[] | undefined>) => {
            state.establishments = action.payload ;
        },
        setPartners: (state, action: PayloadAction<PartnersType[]>) => {
            state.partners = action.payload ;
        },
        setEmployer: (state, action: PayloadAction<EtablissementShortType[]>) => {
            state.employer = action.payload ;
        },
        setBiom: (state, action: PayloadAction<AvgBiomType>) => {
            state.biom = action.payload ;
        },
        setParity: (state, action: PayloadAction<number | undefined>) => {
            state.parity = action.payload ;
        },
     }
 });
 
 export default establishmentsSlice.reducer;
 
 export const { setEstablishments, setBiom, setEmployer, setParity, setPartners } = establishmentsSlice.actions;