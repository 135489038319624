/*************************************************************************
 * 
 * OPEN STUDIO
 * __________________
 * 
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents, 
 * patents in process, and are protected by trade secret or copyright law. 
 * Dissemination of this information or reproduction of this material is strictly 
 * forbidden unless prior written permission is obtained from Open Studio. 
 * Access to the source code contained herein is hereby forbidden to anyone except 
 * current Open Studio employees, managers or contractors who have executed 
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied 
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import React, { Suspense, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import NotFound from "./pages/NotFound";
import { getRegion } from "./api/Global";
import { getEtablissement } from "./api/Etablissements";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { setEtablissementOptions, setRegionData } from "./redux/filterSlice";
import { setOneError } from "./redux/errorSlice";
import { AxiosError } from "axios";
import ReactGA from "react-ga";
import Loader from "./components/loader";

import 'leaflet';
import 'leaflet-providers';
import './lib/CanvasFlowmapLayer';

/*
import PaysPage from "./pages/Pays";
import EtablissementPage from "./pages/Etablissement";
import EtablissementsPage from "./pages/Etablissements";
import ProduitsPage from "./pages/Produits";
import ProduitPage from "./pages/Produit";
import MetiersPage from "./pages/Metiers";
import FiltresPage from "./pages/FiltresGlobaux";
*/

const EtablissementsPage = React.lazy(() => import("./pages/Etablissements"));
const EtablissementPage = React.lazy(() => import("./pages/Etablissement"));
const ProduitsPage = React.lazy(() => import("./pages/Produits"));
const ProduitPage = React.lazy(() => import("./pages/Produit"));
const MetiersPage = React.lazy(() => import("./pages/Metiers"));
const FiltresPage = React.lazy(() => import("./pages/FiltresGlobaux"));
const PaysPage = React.lazy(() => import("./pages/Pays"));



function MainRouter() {
  const dispatch = useDispatch()
  const { regionId, etablissementId, urlForApi, etablissementOptions, regionData, epciId } = useSelector((state: RootState) => state.filter)
  const { error } = useSelector((state: RootState) => state.errors)
  const isEpci = (epciId !== undefined)
  //Initialisation des données asynchrones sur les filtres initiales
  useEffect(() => {
    if (regionId === "") {
      return;
    }
    getRegion({ regionId, part: (isEpci ? 'epci' : 'it') })
      .then(regionData => dispatch(setRegionData(regionData)))
      .catch((error: AxiosError) => {
        dispatch(setOneError(JSON.stringify(error.toJSON())));
      });
  }, [regionId, dispatch, isEpci]);

  useEffect(() => {
    if (!etablissementOptions && etablissementId) {
      getEtablissement(urlForApi, etablissementId).then(
        tmpOptions => dispatch(setEtablissementOptions([
          {
            id: tmpOptions.id,
            value: tmpOptions.id,
            label: tmpOptions.usual_name,
          },
        ])
        )).catch((error: AxiosError) => {
          dispatch(setOneError(JSON.stringify(error.toJSON())));
        });
    }
  }, [etablissementId, dispatch, etablissementOptions, urlForApi]);

  //Initialisation des données asynchrones sur les filtres initiales
  useEffect(() => {
    if (!etablissementOptions && regionData) {
      if (etablissementId) {
        const fetchData = async () => {
          const tmpOptions = await getEtablissement(urlForApi, etablissementId);
          dispatch(setEtablissementOptions([
            {
              id: tmpOptions.id,
              value: tmpOptions.id,
              label: tmpOptions.usual_name,
            },
          ]));
        };
        fetchData().catch((error: AxiosError) => {
          console.log(error)
          dispatch(setOneError(error.message));
        });
      } else {
        dispatch(setEtablissementOptions([]));
      }
    }
  }, [urlForApi, dispatch, etablissementId, etablissementOptions, regionData]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const location = useLocation();
  if (process.env.REACT_APP_GOOGLE_ANALYTIC) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC, { debug: false });
    ReactGA.pageview(location.pathname);
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* Page d'accueil */}
        <Route path="/" exact>
          <Header first={true} />
          <PaysPage />
        </Route>
        <Route path="/territoire" exact>
          <Header />
          <PaysPage
            mode={"territoire"}
          />
        </Route>
        <Route path="/EPCI" exact>
          <Header />
          <PaysPage
            mode={"EPCI"}
          />
        </Route>

        {/* Vues Produits */}
        <Route path="/produits/:zoneId/:etablissementId?">
          <Header />
          <ProduitsPage />
        </Route>
        <Route path="/produit/:zoneId/:produitId?">
          <Header />
          <ProduitPage />
        </Route>

        {/* Vues Géolocalisées */}
        <Route path="/region/:zoneId">
          <Header />
          <EtablissementsPage />
        </Route>
        <Route path="/etablissements/:zoneId" exact>
          <Header />
          <EtablissementsPage />
        </Route>
        <Route path="/etablissements/:zoneId/produit/:produitId">
          <Header />
          <EtablissementsPage />
        </Route>
        <Route path="/etablissements/:zoneId/metier/:metierId">
          <Header />
          <EtablissementsPage />
        </Route>
        <Route path="/etablissement/:zoneId/:etablissementId">
          <Header />
          <EtablissementPage />
        </Route>

        {/* Vues Métiers */}
        <Route path="/metiers/:zoneId">
          <Header />
          <MetiersPage />
        </Route>
        <Route path={"/filtres/:redirect"}>
          <FiltresPage />
        </Route>

        <Route>
          <Header first={true} />
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default MainRouter;
