/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
 import { createSlice, PayloadAction } from "@reduxjs/toolkit";

 export type ErrorState = {
    error?:string
 };

 export const initialState:ErrorState = {
     error: undefined,
 }
 
 
 export const errorSlice = createSlice({
     name: 'error',
     initialState,
     reducers: {
         setOneError: (state, action: PayloadAction<string>) => {
             state.error = action.payload
         }
     }
 });
 
 export default errorSlice.reducer;
 
 export const { setOneError } = errorSlice.actions;