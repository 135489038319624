/*************************************************************************
 * 
 * OPEN STUDIO
 * __________________
 * 
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents, 
 * patents in process, and are protected by trade secret or copyright law. 
 * Dissemination of this information or reproduction of this material is strictly 
 * forbidden unless prior written permission is obtained from Open Studio. 
 * Access to the source code contained herein is hereby forbidden to anyone except 
 * current Open Studio employees, managers or contractors who have executed 
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied 
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
/**
 * Récupération des routes de configuration (alimentation des menus, selects, etc...)
 */
import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_URL}visualisation`;

/**
 * Détail de la région, liste des TI, capacité RCA de la région
 * @param {number} regionId
 * @returns {Promise<object>}
 * - department, array, liste des départements de la région
 * - industry_territory, array, liste des TI de la région
 * - region, array, détail de la région
 */
export type DepartmentType = {
  code: string;
  name: { fr: string };
  region_id: number;
  slug: string;
}

export interface CommonRegionType {
  name: {
    fr: string
  },
  coef_rca: number
  score: {
    "20": {
      code: string
      name: {
        fr: string
      },
      value: number
    }
  }
}

type PointTuple = [number, number];
type Polyline = PointTuple[] ;

export interface RegionType extends CommonRegionType {
  code: string
  slug: string
  country_id: number
  poly: Polyline[]
}

export interface IndustryTerritoryType extends CommonRegionType {
  national_identifying: string
}

export interface EpciType extends CommonRegionType {
  siren: string
  region: string
  poly: Polyline[]
}

export type GetRegionType = {
  department: DepartmentType[];
  industry_territory: IndustryTerritoryType[];
  region: RegionType[];
  epci: EpciType[];
}
export async function getRegion({ regionId, part }: { regionId: string, part?:string }): Promise<GetRegionType> {
  const res = await axios.get(`${baseUrl}/region/${regionId}${part ? `?part=${part}`: ''}`);
  return res.data;
}

export type CountType = {
  job: number
  product: number
  establishment: number
}