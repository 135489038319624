/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegionType, IndustryTerritoryType, EpciType, DepartmentType, GetRegionType } from "../api/Global";

export type DataState = {
    regions: RegionType[]
    territoires: IndustryTerritoryType[]
    epci: EpciType[]
    department: DepartmentType[]
}
export const initialState: DataState = {
    regions: [],
    territoires: [],
    epci: [],
    department: [],
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<GetRegionType>) => {
            state.regions = action.payload.region;
            state.territoires = action.payload.industry_territory;
            state.epci = action.payload.epci;
            state.department = action.payload.department;
        },
        setDataRegion: (state, action: PayloadAction<RegionType[]>) => {
            state.regions = action.payload;
        },
        setDataTerritoire: (state, action: PayloadAction<IndustryTerritoryType[]>) => {
            state.territoires = action.payload;
        },
        setDataEpci: (state, action: PayloadAction<EpciType[]>) => {
            state.epci = action.payload;
        },
    }
});

export default dataSlice.reducer;

export const { setData, setDataEpci, setDataRegion, setDataTerritoire } = dataSlice.actions;