/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export const initialState = {
    advantage: 0, // Competitive advantage
    pci: 0, // Economic growth
    resilience: 0, // Productive resilience
    maslow: 0, // Securing basic necessities
    green: 0, // Green production
}

export type ObjectiveState = typeof initialState;

export const objectiveSlice = createSlice({
    name: 'objectives',
    initialState,
    reducers: {
        setMaslow: (state, action: PayloadAction<number>) => {
            state.maslow = action.payload;
        },
        setResilience: (state, action: PayloadAction<number>) => {
            state.resilience = action.payload;
        },
        setGreen: (state, action: PayloadAction<number>) => {
            state.green = action.payload;
        },
        setPci: (state, action: PayloadAction<number>) => {
            state.pci = action.payload;
        },
        setAdvantage: (state, action: PayloadAction<number>) => {
            state.advantage = action.payload;
        },
    }
});

export default objectiveSlice.reducer;

export const { setAdvantage, setGreen, setMaslow, setPci, setResilience } = objectiveSlice.actions;