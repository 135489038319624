/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./css/main.css";
import "./css/App.css";
import "./css/custom_bootstrap.css";
import MainRouter from "./Router";
import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter as Router } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from "react-device-detect";
import useScreenOrientation from "./Utility/screenOrientation";

import "./App.scss";
function App() {
  const { t } = useTranslation();
  const urlMaps = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
  const orientation = useScreenOrientation();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('ATLAS de la résilience productive')}</title>
        {process.env.REACT_APP_USE_GOOGLE_API === "true" ? (
          <script src={urlMaps} />
        ) : null}
      </Helmet>
      <ErrorBoundary>
        <div className={`App ${isMobileOnly && "MobileOnly"} ${orientation}`}>
            <Router>
              <MainRouter />
            </Router>
        </div>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
