/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
    nbProduit: 0,
    nbMetier: 0,
    nbEtablissement: 0,
    nbFormation: 0,
    nbIndividu: 0,
    countInit: false
}

export type CountState = typeof initialState;

export const countSlice = createSlice({
    name: 'count',
    initialState,
    reducers: {
        setCount: (state, action: PayloadAction<{
            nbProduit: number,
            nbEtablissement: number,
            nbMetier: number
        }>) => {
            state.nbEtablissement = action.payload.nbEtablissement;
            state.nbProduit = action.payload.nbProduit;
            state.nbMetier = action.payload.nbMetier;
            state.countInit = true;
        }
    }
});

export default countSlice.reducer;

export const { setCount } = countSlice.actions;