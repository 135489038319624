/*************************************************************************
 *
 * OPEN STUDIO
 * __________________
 *
 *  [2020] - [2021] Open Studio All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Open Studio. The intellectual and technical concepts contained herein are
 * proprietary to Open Studio and may be covered by France and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is strictly
 * forbidden unless prior written permission is obtained from Open Studio.
 * Access to the source code contained herein is hereby forbidden to anyone except
 * current Open Studio employees, managers or contractors who have executed
 * Confidentiality and Non-disclosure agreements explicitly covering such access.
 * This program is distributed WITHOUT ANY WARRANTY; without even the implied
 * warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 */
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="bg-blue-100 text-pink-800 inset-0 absolute w-full h-full text-center flex items-center justify-center">
      <div>
        <div className="text-5xl font-bold mt-8">404</div>
        <div className="text-xl font-bold mt-2">{t("Oups...")}</div>
        <div className="">
        {t("La page que vous recherchez")}<br />
        {t("est introuvable")}
        </div>

        <Link
          to="/"
          className="btn inline-block mt-12 text-black"
          style={{
            minWidth: "203px",
          }}
        >
          {t("Retour à l'Accueil")}
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
